import React from 'react'
import { Card } from 'theme-ui'
import { Layout, Stack, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Sticky from '@components/Sticky'
import Seo from '@widgets/Seo'
import AuthorCompact from '@widgets/AuthorCompact'
import TableOfContentsCompact from '@widgets/TableOfContentsCompact'
import {
  PostHead,
  PostImage,
  PostBody,
  PostTagsShare,
} from '@widgets/Post'

import Review from '../../components/Review'
import GameInfos from '../../components/GameInfos'
import ShareSocialMedia from '../../components/ShareSocialMedia'

const Post = ({
  data: { post, tagCategoryPosts, tagPosts, categoryPosts },
  ...props
}) => {
  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : [])
  ]
  const { pageContext: { services = {}, siteUrl } = {} } = props

  return (
    <Layout {...props}>
      <Seo {...post} siteUrl={siteUrl} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PostHead {...post} />
      </Stack>
      <Divider />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <Card variant='paper'>
            <PostImage {...post} inCard />
            <PostBody {...post} />
          </Card>
          <Divider />
          {
          post.conclusion &&
            <div>
              <Card variant='paper'>
                <Review {...post} />
                <PostTagsShare {...post} location={props.location} />
              </Card>
            </div>
          }
          <Divider />    
          {post.category && (
            <CardList
              nodes={relatedPosts}
              variant={['vertical-cover']}
              columns={[1, 2, 3, 3]}
              limit={6}
              title='Ça pourrait vous intéresser :'
              distinct
              omitCategory
              omitAuthor
            />
          )}
          <Divider />
        </Main>
        <Sidebar>
          <AuthorCompact author={post.author} omitTitle />
          <Divider />
          <GameInfos {...post}/>
          <Divider />
          <Sticky>
            {post.tableOfContents?.items && (
              <>
                <TableOfContentsCompact {...post} />
                <Divider />
              </>
            )}
          <ShareSocialMedia {...props}/>
          </Sticky>
        </Sidebar>
      </Stack>
    </Layout>
  )
}

export default Post
