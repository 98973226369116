import React from 'react'
import { Flex, Box, Heading, Text, Image } from 'theme-ui'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const styles = {
  wrapper: {
    alignItems: `baseline`,
    flexDirection: [`column`, `row`],
    color: 'article',
    fontSize: 3,
    mb: 4,
    code: {
      fontSize: `inherit`
    }
  },
  left: {
    flexBasis: `1/2`,
    '@media screen and (min-width: 650px)': {
    paddingRight: `10%`,
    marginTop: `-1px`,
    marginLeft: `-1px`,
    borderRight: `solid 1px`,
    },
    '@media screen and (max-width: 650px)': {
      mr: 0,
      mt: 5,
      width: `100%`
    },
  },
  right: {
    '@media screen and (min-width: 650px)': {
      paddingLeft: `5%`,
      marginTop: `-1px`,
      marginLeft: `-1px`,
      borderLeft: `solid 1px`,
      flexBasis: `1/2`,
    },
    '@media screen and (max-width: 650px)': {
      ml: 0,
      mt: 5,
      width: `100%`
    },
  },
  heading: {
    flex: `auto`,
    color: `omegaDark`,
    whiteSpace: `nowrap`,
    position: `relative`,
    left: `39px`,
    '@media screen and (max-width: 650px)': {
      left: 0,
    },
    m: 0  
  },
  reviewText: {
    color: 'article',
    fontSize: 3,
    mb: 4,
    code: {
      fontSize: `inherit`
    }
  },
  wrapperConclusion: {
    mt: 2,
    display: `inline-flex`,
    justifyContent: `center`,
    alignItems: `center`,
    width: `100%`,
    '@media screen and (max-width: 650px)': {
      display: `contents`
    },
  },
  conclusion: {
    color: 'article',
    fontSize: 3,
    mb: 4,
    width: `70%`,
    code: {
      fontSize: `inherit`
    },
    marginTop: `5%`,
    '@media screen and (max-width: 650px)': {
      display: `block`,
      mb: 0,
      width: `100%`,
    },
  },
  notation: {
    '@media screen and (max-width: 700px)': {
      width: `150px`,
      height: `150px`,
      mt: `2em`,
      mb: `7em`,
      ml: `5em`,
    },
    height: `200px`,
    mr: `4em`,
    width: `20%`,
    textAlign: `center`,
    fontWeight: `bolder`,
    fontSize: `18px`,
  },
  img: {
    height: `30px`,
    width: `30px`,
    mr: 2,
    top: `9px`,
    position: `relative`
  },
}

const commentaries = [
  'Ah !', 
  'Affreux', 
  'Terrible', 
  'Décevant', 
  'Convenable', 
  'Sympa sans plus', 
  "C'est chouette", 
  'Incroyable', 
  'Indispensable', 
  'Simplement parfait'
]

const Review = ({positive, negative, conclusion, notation}) => (
  <div>
    <Flex sx={styles.wrapper}>
      {
      positive && 
        <Box sx={styles.left}>
          <Heading variant='h2' sx={styles.heading}>
            Points positifs
          </Heading>
          <Text sx={styles.reviewText}>{positive.split('|').reduce((all, cur) => [
              ...all,
              <br/>,
              <Image sx={styles.img} src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Ambox_emblem_plus.svg/1200px-Ambox_emblem_plus.svg.png" />, 
              cur
            ])}
          </Text>
        </Box>
      }
      {negative && 
        <Box sx={styles.right}>
          <Heading variant='h2' sx={styles.heading}>
            Points négatifs
          </Heading>
          <Text sx={styles.reviewText}>{negative.split('|').reduce((all, cur) => [
              ...all,
              <br />,
              <Image sx={styles.img} src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Ambox_emblem_minus.svg/1200px-Ambox_emblem_minus.svg.png" />, 
              cur
            ])}
          </Text>
        </Box>
      }
    </Flex>
    {positive && negative && <hr />}
    {conclusion && 
      <Box sx={styles.wrapperConclusion}>
        {notation && 
          <Box sx={styles.notation}>
            <CircularProgressbar 
              styles={{
                root: {
                  borderBottom: '2px solid',
                  paddingBottom: '2em',
                  stroke: `rgba(99, 66, 245, ${notation / 100})`,
                },
                path: {
                  stroke: notation > 4 ? 'green' : 'red',
                },
                text: {
                  fontWeight: 'bold',
                  fill: notation > 4 ? 'green' : 'red',
                  fontSize: '2.5em'
                }
              }} 
              value={notation} 
              maxValue={10} 
              text={`${notation}`} 
            />
            <Text>{commentaries.filter((commentary, index) => notation -1 === index)}</Text>
          </Box>
        }
        <Text sx={notation && styles.conclusion || styles.reviewText}><strong>{conclusion}</strong></Text>
      </Box>
    }
</div>
);

export default Review
