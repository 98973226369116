import React from 'react'
import { Box, Heading, Card, Text } from 'theme-ui'
import Section from '@components/Section'

const styles = {
  wrapper: {
    textAlign: `left`
  },
  title: {
    color: `omegaDark`,
    margin: '0.3em'
  },
  text: {
    margin: '0.5em 0 1.5em 1em',
    fontSize: '20px'
  },
  finaltext: {
    margin: '0.5em -0.5em 0.3em 1em',
    fontSize: '20px'
  }
}

const GameInfos = ({ developer, editor, releaseDate, platform, price }) => {
  return (
    developer && editor && releaseDate && platform && (
        <Section aside>
        <Card variant='paper'>
            <Box sx={styles.wrapper}>
              <Heading variant='h3' sx={styles.title}>
                  DÉVELOPPEUR :
              </Heading>
              <Text sx={styles.text}>{developer}</Text>
              <Heading variant='h3' sx={styles.title}>
                  ÉDITEUR :
              </Heading>
              <Text sx={styles.text}>{editor}</Text>
              <Heading variant='h3' sx={styles.title}>
                  DATE DE SORTIE : 
              </Heading>
              <Text sx={styles.text}>{releaseDate}</Text>
              <Heading variant='h3' sx={styles.title}>
                  PLATEFORME :
              </Heading>
              <Text sx={styles.text}>{platform}</Text>
              <Heading variant='h3' sx={styles.title}>
                PRIX À LA SORTIE :
              </Heading>
              <Text sx={styles.finaltext}>{price.split('.').reduce((all, cur) => [
                  ...all,
                  <br/>,
                  <br/>,
                  cur
                ])}
              </Text>
          </Box>
        </Card>
        </Section>
    )
  )
}

export default GameInfos
